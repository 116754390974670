<template>
  <div>
    <search-filter
        :items="outgoingInvoices"
        :isActionColumnEnabled="true"
        itemView="list"
        :sortOptions="sortOptions"
        :columns="columns"
        :show-past-years-filter="true"
        @columnClick="!readOnly?($event.billNumber == null?selectOutgoingInvoice($event):$router.push({ name: 'OutgoingInvoice view', params: { outgoingInvoiceId: $event.id }})):false"
        @addItem="addOutgoingInvoice"
    >
      <!--  =========================== -->
      <!--  ===    Results infos    === -->
      <!--  =========================== -->
      <template #resultInfos="{items}">
        ({{ items.length }})
        <span>{{ currency(getPreTaxAmounts(items)) }} HT / {{ currency(getTotalAmounts(items)) }} TTC</span>
<!--        <span v-if="!readOnly">{{ currency(getPreTaxAmounts(items)) }} HT</span>-->
<!--        <span v-else>{{ currency(getTotalAmounts(items)) }} TTC</span>-->
      </template>

      <!--  =========================== -->
      <!--  ===      Grid view      === -->
      <!--  =========================== -->
      <template v-slot:gridView="{item}">
        <card-outgoing-invoice
            :outgoing-invoice="item"
            @click="item.billNumber == null?selectOutgoingInvoice(item):$router.push({ name: 'OutgoingInvoice view', params: { outgoingInvoiceId: item.id }})"
            @deleteOutgoingInvoice="deleteOutgoingInvoiceAlert(item)"
        />
      </template>

      <!--  =========================== -->
      <!--  ===      List view      === -->
      <!--  =========================== -->

      <!--      Column status-->
      <template #listView_head_status="{item}">
        <icon icon="chart-line"/>
      </template>
      <template #listView_cell_status="{item}">
        <b-avatar
            :id="`invoice-row-${item.id}`"
            size="32"
            :variant="`light-${getOutgoingInvoiceIconStatus(item).variant}`"
        >
          <icon
              :icon="getOutgoingInvoiceIconStatus(item).icon"
          />
        </b-avatar>
        <b-tooltip
            :target="`invoice-row-${item.id}`"
            placement="left"
        >
          <p class="mb-0">
            {{ capitalize($t(getOutgoingInvoiceStatus(item))) }}
          </p>
        </b-tooltip>
      </template>

      <!--      Column payment status-->
      <template #listView_head__paymentStatus="{item}">
        <icon icon="cash-register"/>
      </template>
      <template #listView_cell__paymentStatus="{item}">
        <b-badge
            v-if="item._paymentStatus != null"
            pill
            :variant="displayPaymentStatus(item._paymentStatus).variant"
        >
          {{ displayPaymentStatus(item._paymentStatus).value }}
        </b-badge>
      </template>

      <!--      Cell contact-->
      <template #listView_cell_contact="{item}">
        {{
          item.customerCompany ? item.customerCompany._display : (item.customerIndividual ? item.customerIndividual._display : '-')
        }}
      </template>


      <!--      Column accountant downloaded-->
      <template #listView_head_accountantDownloaded="{item}">
        <icon v-b-tooltip.hover.top="capitalize($t('DownloadedForAccountant'))"
              icon="copy"/>
      </template>
      <template #listView_cell_accountantDownloaded="{item}">
        <span v-if="item.lastExport != null">
          {{ item.lastExport|moment('L') }}
        </span>
        <span v-else>-</span>
      </template>

      <!--      Actions-->
      <template #listView_cell_actions="{item}">
        <div v-if="!readOnly"
             class="text-nowrap">

          <!--          View-->
          <button-view
              @click.native.stop="$router.push({ name: 'OutgoingInvoice view', params: { outgoingInvoiceId: item.id }})"
              v-b-tooltip.hover.left="$t('View')"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <!--          Download-->
          <button-download
              @click.native.stop="downloadOutgoingInvoice(item)"
              v-b-tooltip.hover.left="$t('Download')"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <b-dropdown
              variant="link"
              toggle-class="p-0"
              no-caret
              right
          >
            <template #button-content>
              <button-dropdown
                  :withIcon="true"
                  :withText="false"
                  :withBorder="false"
                  size="sm"
              />
            </template>

            <!--          Validate-->
            <b-dropdown-item
                v-if="item.billNumber == null"
                @click.stop="validateOutgoingInvoiceLocal(item)">
              <icon
                  icon="check"
                  class="cursor-pointer"/>
              <span class="align-middle ml-1">{{ $t('validate') }}</span>
            </b-dropdown-item>

            <!--          Edit-->
            <b-dropdown-item
                v-if="item.billNumber == null"
                @click.stop="$router.push({ name: 'OutgoingInvoice edit', params: { outgoingInvoiceId: item.id }})">
              <icon
                  icon="edit"
                  class="cursor-pointer"/>
              <span class="align-middle ml-1">{{ $t('Edit') }}</span>
            </b-dropdown-item>

            <!--          Add payment-->
            <b-dropdown-item
                v-if="item.billNumber != null"
                @click.stop="addPayment('outgoingInvoice', item)"
            >
              <icon
                  icon="cash-register"
                  class="cursor-pointer"/>
              <span class="align-middle ml-1">{{ capitalize($t('addPayment')) }}</span>
            </b-dropdown-item>

            <!--          Archivate-->
            <b-dropdown-item
                v-if="item.isArchived == false"
                @click.stop="archivateOutgoingInvoiceLocal(item, true)"
            >
              <icon
                  icon="box"
                  class="cursor-pointer"/>
              <span class="align-middle ml-1">{{ capitalize($t('Archivate')) }}</span>
            </b-dropdown-item>

            <!--          Un archivate-->
            <b-dropdown-item
                v-if="item.isArchived == true"
                @click.stop="archivateOutgoingInvoiceLocal(item, false)"
            >
              <icon
                  icon="box-open"
                  class="cursor-pointer"/>
              <span class="align-middle ml-1">{{ capitalize($t('Unarchive')) }}</span>
            </b-dropdown-item>

            <!--          Duplicate -->
            <b-dropdown-item
                @click.stop="duplicateOutgoingInvoice(item)"
            >
              <icon
                  icon="copy"
                  class="cursor-pointer"/>
              <span class="align-middle ml-1">{{ capitalize($t('duplicate')) }}</span>
            </b-dropdown-item>

            <!--          Delete -->
            <b-dropdown-item
                v-if="item.billNumber == null"
                @click.stop="deleteOutgoingInvoiceAlert(item)"
            >
              <icon
                  icon="times"
                  class="cursor-pointer"/>
              <span class="align-middle ml-1">{{ capitalize($t('delete')) }}</span>
            </b-dropdown-item>

          </b-dropdown>


        </div>

        <div v-else
             class="text-nowrap">
          <!--          View-->
          <router-link
              :to="$router.resolve({ name: 'OutgoingInvoice view', params: { outgoingInvoiceId: item.id } }).href"
              target="_blank"
              class="text-decoration-none"
          >
            <icon
                v-b-tooltip.hover.left="$t('View')"
                icon="external-link-alt"
                class="cursor-pointer"/>
          </router-link>

          <!--          Download-->
          <button-download
              @click.native.stop="downloadOutgoingInvoice(item)"
              v-b-tooltip.hover.left="$t('Download')"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />
        </div>
      </template>

    </search-filter>


    <!-- modal outgoing invoice-->
    <modal-outgoing-invoice
        :outgoingInvoice.sync="currentOutgoingInvoice"
        :title="outgoingInvoiceModalTitle"
        :isOpen.sync="outgoingInvoiceModalShow"
        @submitValidatedOutgoingInvoice="submitValidatedOutgoingInvoiceLocal"
    />

    <!-- modal payment-->
    <modal-payment
        :payment.sync="currentPayment"
        :title="paymentModalTitle"
        :isOpen.sync="paymentModalShow"
        :absoluteDisplay="true"
        @submitValidatedPayment="submitValidatedPaymentLocal"
    />

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { mixinOutgoingInvoices } from '../../../views/sales/outgoingInvoices/mixinOutgoingInvoices'
import { useOutgoingInvoices } from '@/views/sales/outgoingInvoices/useOutgoingInvoices'

import SearchFilter from '../../searchFilterV2/SearchFilter'
// import CardOutgoingInvoice from './components/_card_outgoing_invoice'
import CardOutgoingInvoice from '@/components/card/OutgoingInvoice'
import i18n from '@/libs/i18n'
import ButtonView from '@/components/button/View'
import ButtonDownload from '@/components/button/Download'
import ButtonDropdown from '@/components/button/Dropdown'
import { mixinPayments } from '../../../mixins/mixinPayments'
import { capitalize } from '../../../utils/filter'
import { getRoute } from '@/utils/utils'

export default {
  components: {
    SearchFilter,
    CardOutgoingInvoice,
    ButtonView,
    ButtonDownload,
    ButtonDropdown
  },
  mixins: [mixinOutgoingInvoices, mixinPayments],
  props: {
    outgoingInvoices: {
      type: Array,
      default: () => []
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const sortOptions = ref([
      {
        text: capitalize(i18n.tc('company')),
        key: '_filter_isCustomerCompany',
      },
      {
        text: capitalize(i18n.tc('individual')),
        key: '_filter_isCustomerIndividual',
      },
      {
        text: capitalize(i18n.tc('draft')),
        key: '_filter_isDraftStatus',
      },
      {
        text: capitalize(i18n.t('sent')),
        key: '_filter_isSentStatus',
      },
      {
        text: capitalize(i18n.t('archived')),
        key: '_filter_isArchived',
      },
      {
        text: capitalize(i18n.t('active')),
        key: '_filter_isActive',
        selected: getRoute().name == 'Workflow view'?false:true
      },
      {
        text: capitalize(i18n.t('incompletePayment')),
        key: '_filter_isPaymentIncomplete',
      },
      {
        text: capitalize(i18n.t('completePayment')),
        key: '_filter_isPaymentComplete',
      },
      {
        text: capitalize(i18n.t('myOutgoingInvoices')),
        key: '_filter_myOutgoingInvoices',
        selected: getRoute().name == 'Workflow view'?false:true
      },
      {
        text: capitalize(i18n.t('notForwardedToAccountant')),
        key: '_filter_isNotForwardedToAccountant',
      },
    ])

    const columns = ref([
      {
        display: '#',
        key: 'billNumber',
        sortable: true
      },
      {
        display: capitalize(i18n.t('billingDate')),
        key: 'billingDate',
        isDate: true,
        sortable: true
      },
      {
        display: capitalize(i18n.t('status')),
        key: 'status',
        sortable: true
      },
      {
        display: capitalize(i18n.t('paymentStatus')),
        key: '_paymentStatus',
        sortable: true
      },
      {
        display: capitalize(i18n.t('contact')),
        key: 'contact',
        sortable: true
      },
      {
        display: capitalize(i18n.t('title')),
        key: 'title',
        sortable: true
      },
      {
        display: capitalize(i18n.t('preTaxAmount')),
        key: 'preTaxAmount',
        sortable: true
      },
      {
        display: capitalize(i18n.t('totalAmount')),
        key: 'totalAmount',
        sortable: true
      },
      {
        display: capitalize(i18n.t('accountantDownloaded')),
        key: 'accountantDownloaded',
        sortable: true
      },
    ])

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const {
      getOutgoingInvoiceStatus,
      getOutgoingInvoiceIconStatus,
    } = useOutgoingInvoices()

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      sortOptions,
      columns,

      // Computed

      // Methods
      getOutgoingInvoiceStatus,
      getOutgoingInvoiceIconStatus,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>